$colours: (
        "1": #FF4081,
        "2": #3D5AFE,
        "3": #C6FF00,
        "4": #651FFF,
        "5": #FF9100,
        "6": #00E5FF,
        "7": #D500F9,
        "8": #FF4081,
        "9": #00E676,
        "10": #FFAB00,
        "11": #B388FF,
        "12": #1DE9B6,
        "13": #D50000,
);

@each $name, $colour in $colours {
  .border-colour-#{$name} {
    border-color: $colour
  }

  .text-colour-#{$name} {
    color: $colour;
  }
}