@font-face {
  font-family: 'Lobster';
  src: local('Lobster'), url("../Fonts/Lobster-Regular.ttf") format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), url("../Fonts/Montserrat-Regular.ttf") format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), url("../Fonts/Montserrat-Italic.ttf") format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), url("../Fonts/Montserrat-Medium.ttf") format('truetype');
  font-weight: 500;
}