@use "sass:color";

.grow {
  flex: 1 0 auto;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.bid-players-hand {
  padding: 10px 0px;
}

/* bidding box */
.bidding-box {
  display: flex;
  width: 370px;
  flex-direction: column;

  background-color: white;
  padding: 10px 20px;
  border: 1px solid #d8dee2;
  border-radius: 4px;
}

.bidding-box-row {
  display: flex;
}

.bid {
  display: flex;
  justify-content: center;
  align-items: center;

  flex: 1 1 auto;

  cursor: default;

  > h3 {
    margin: 0px;
  }
}

$bidding-cards:
        "contract" $gray-100 $black,
        "double" $red $white,
        "pass" $green $white;

@each $name, $colour, $text-colour in $bidding-cards {
  .bidding-box-card-#{$name} {
    width: 65px;
    height: 45px;

    margin: 1px;
    border-radius: 4px;
    border-width: 1px;

    color: $text-colour !important;
    background-color: $colour;

    @include clickable-div($colour, $colour);

    &.disabled {
      opacity: 0.2;
      background-color: darken($colour, 5%);
      cursor: default;
    }
  }
}

.bid-suit {
  height: 40px;
  width: 40px;
  margin-left: -5px;
}

.blue {
  color: $blue;
}

.orange {
  color: $orange;
}

.red {
  color: $red;
}

.green {
  color: $green;
}