@use "sass:color";

$primary:       #d62828 !default;
$secondary:     #003049 !default;
$light:         #eae2b7 !default;

$hand-card-width: 55px;
$hand-card-height: 75px;

body {
    font-family: 'Montserrat', sans-serif !important;
}

.primary {
    color: $primary;
}

.secondary {
    color: $secondary;
}

@mixin clickable-div($background-color, $border-color, $hover-color: darken($background-color, 15%)) {
    cursor: pointer;

    &:hover:not(.disabled) {
        background-color: $hover-color;
        border-color: $hover-color;
    }
}

@mixin center-div() {
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media only screen and (min-width: 1200px) {
    :not(.desktop).tablet, :not(.desktop).mobile {
        display: none;
    }
}

@media only screen and (max-width: 1200px) and (min-width: 768px) {
    :not(.tablet).mobile, :not(.tablet).desktop {
        display: none;
    }
}

@media only screen and (max-width: 768px) {
    :not(.mobile).tablet, :not(.mobile).desktop {
        display: none;
    }
}

// Import Bootstrap and its default variables
@import '../../node_modules/bootstrap/scss/bootstrap';

/* App */
.remove-margin {
    margin: 0;
}

.text-center {
    text-align: center;
}

.bold {
    font-weight: bold;
}

.inline-block {
    display: inline-block;
}

/* Nav Bar */
.nav-icons {
    margin-left: auto;
}

.header-logo {
    color: white !important;
    text-decoration: none !important;
}

/* Game Area */
.game-area {
    padding: 0.5rem 1rem;
}

.playing-grid {
    display: flex;
    justify-content: center;
    align-items: center;
}

.north-south {
    flex: 1 0 10%;

    display: flex;
    flex-direction: column;
}

.east-west {
    flex: 1 0 30%;

    display: flex;
    flex-direction: row;

    align-items: center;
}

.north {
    margin-bottom: 50px;
}

.east {
    justify-content: flex-start;
}

.west {
    justify-content: flex-end;
}

.column {
    flex-direction: column;
}

/* StraightHand Component */
.center-player {
    flex-grow: 0;
    width: 30%;

    margin: auto;
}

/* players card */
.players-card {
    @include center-div();
}

/* table card */
.table-card {
    width: 70px;
    height: 100px;

    border: solid;
    border-radius: 10px;
    border-width: 2px;
    border-color: lightgray;

    padding: 5px;
}

.table-suit {
    height: 60px;
    width: 60px;
    margin-top: -10px;
}

/* dummy card */
.dummy-suit {
    display: inline-block;
    height: 20px;
    width: 20px;
}

.dummy-cards {
    display: inline-block;

    margin: 0;
}

/* player name */
.player-name {
    font-style: italic;
    margin: 0;
}

/* contract progress */
.cp-contract {
    display: flex !important;
    align-items: center;
}

.cp-contract-icon {
    height: 26px;
}

/* East West Dummy StraightHand */
.ew-dummy-hand {
    display: flex;
    flex-direction: column;
}

.ew-dummy-suit {
    display: flex;

    height: 55px;
}

.ns-dummy-hand {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.ns-dummy-suit {
    display: flex;
    flex-direction: column;

    width: $hand-card-width;
}

.ns-dummy-card {
    height: 55px;
}

/* TABLE */
.next-stage-alerts {
    @include center-div();
}