@use "sass:color";

/* LOGO */
.brand-logo {
  display: flex;
  align-items: center;

  padding: 30px 15px 30px 15px;

  &.logo-small {
    padding: 0px;
  }

  .brand-img {
    width: 80px;
    height: 80px;

    margin: 0px -10px;

    &.logo-small {
      width: 40px;
      height: 40px;

      margin: -3px;
    }
  }

  .brand-logo-text {
    font-family: Lobster;

    &.logo-small {
      font-size: 1.75rem;
    }
  }
}

/* BRAND LOADING INDICATOR */
.brand-loading-indicator {
  @include center-div();
}

/* SELECT LIST */
.select-list {
  display: flex;
  flex-direction: column;
}

.select-list-option {
  @include clickable-div(white, $secondary, lighten($secondary, 10%));

  margin: 10px 0px;
  padding: 5px;

  text-align: center;
  color: $secondary;
  background-color: white;
  text-transform: uppercase;

  border: solid;
  border-radius: 0.25rem;
  border-width: 1px;
  border-color: $secondary;

  &:hover {
    color: white;
  }
}

.selected-option {
  background-color: $secondary;
  border-color: $secondary;
  color: white;

  &:hover {
    background-color: $secondary !important;
    border-color: $secondary !important;
    color: white;
  }
}

/* WORKFLOW PORTAL */
$margin: 15px;
.workflow-portal {
  @include center-div();
}

.workflow-portal-title {
  font-weight: normal;
}

.workflow-portal-body {
  width: 350px;
  padding: 15px 20px;
}

.workflow-header {
  margin-bottom: $margin;
}

.workflow-footer {
  display: flex;
  justify-content: flex-end;

  margin-top: $margin;
}