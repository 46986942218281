/* hand card */
.hand {
  display: flex;
  justify-content: center;
}

.by-suit {
  display: flex;
}

.hand-card {
  width: $hand-card-width;
  height: $hand-card-height;

  border: solid;
  border-radius: 10px;
  border-width: 1px;
  border-color: lightgrey;

  background-color: $white;
  padding: 2px;
  margin: 1px;
}

.clickable-hand-card {
  @include clickable-div($white, lightgrey);
}

.hand-suit {
  height: 50px;
  width: 50px;
  margin-top: -20px;
}

.hand-number {
  padding-left: 5px;
}

.inactive {
  opacity: 0.4;
}